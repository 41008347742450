import request from "@/utils/axios";
// 登陆
const login = (data: any) => {
  return request("/admin/login/login", "POST", data);
};
// 首页
const main = (data: any) => {
  return request("/admin/main/index", "GET", data);
};
// 退出登陆
const wechatLogout = (data: any) => {
  return request("/admin/Login/wechatLogout", "GET", data);
};
// 修改密码
const newspasswd = (data: any) => {
  return request("/admin/personal/passwd", "POST", data);
};
// 获取家长openid
const fetchFamilyOpenid = (data: any) => {
  return request("/admin/Login/fetchFamilyOpenid", "GET", data);
};
// 退出登陆
const unlogin = (data: any) => {
  return request("/admin/login/logout", "POST", data);
};
// 首页
const adminInfo = (data: any) => {
  return request("/admin/index/index", "POST", data);
};
// 模块权限
const moduleMenu = (data: any) => {
  return request("/admin/login/getmenu", "GET", data);
};
// 菜单权限
const permission = (data: any) => {
  return request("/admin/login/permission", "GET", data);
};
// 通知公告
const notice = (data: any) => {
  return request("/admin/warehouse/warehousenotify/select", "GET", data);
};
// 我的消息
const mymessage = (data: any) => {
  return request("/admin/systemnotice/select", "POST", data);
};
// 学校概况
const schooldata = (data: any) => {
  return request("/admin/index/index", "POST", data);
};
// 数据概况
const statisticsIndex = (data: any) => {
  return request("/admin/warehouse/statistics/index", "GET", data);
};
// 获取所有仓库
const allwarehouses = (data: any) => {
  return request("/admin/warehouse/noneedauth/warehouses", "GET", data);
};
// 获取所有物品
const noneedauthgoodslib = (data: any) => {
  return request("/admin/warehouse/noneedauth/goodslib", "GET", data);
};
// 警报记录
const warningSelect = (data: any) => {
  return request("/admin/warehouse/warehousewarn/select", "GET", data);
};
// 智能设备
const toolsguanli = (data: any) => {
  return request("/admin/warehouse/warehousecabinet/select", "GET", data);
};
// 仓库管理列表
const warehouseSelect = (data: any) => {
  return request("/admin/warehouse/warehouse/select", "GET", data);
};
// 审批记录
const myApprovelog = (data: any) => {
  return request("/admin/warehouse/warehouseaudit/logs", "GET", data);
};
// 待审批列表
const myApprove = (data: any) => {
  return request("/admin/warehouse/warehouseaudit/select", "GET", data);
};
// 我的申请列表
const myapplySelect = (data: any) => {
  return request("/admin/warehouse/warehouseapply/select", "GET", data);
};
// 获取所有管理员
const alladmin = (data: any) => {
  return request("/admin/warehouse/noneedauth/admins", "GET", data);
};
// 借还记录
const circulateLog = (data: any) => {
  return request("/admin/warehouse/warehousecabinetorder/select", "GET", data);
};
// 我的申请详情
const myapplyDetail = (data: any) => {
  return request("/admin/warehouse/warehouseapply/detail", "GET", data);
};
// 获取所有在库的工具
const allintools = (data: any) => {
  return request("/admin/warehouse/noneedauth/inLibGoods", "GET", data);
};
// 获取所有项目
const projects = (data: any) => {
  return request("/admin/warehouse/noneedauth/projects", "GET", data);
};
// 仓储项目拉
const noneedauthprojecting = (data: any) => {
  return request("/admin/warehouse/noneedauth/projecting", "GET", data);
};
// 提交申请
const myapplyInsert = (data: any) => {
  return request("/admin/warehouse/warehouseapply/insert", "POST", data);
};
// 仓库管理修改
const warehouseUpdate = (data: any) => {
  return request("/admin/warehouse/warehouse/update", "POST", data);
};
// 根据仓库，日期获取统计数据
const datestatistics = (data: any) => {
  return request("/admin/warehouse/statistics/echart", "GET", data);
};
// 根据物品获取所有库存
const allinventory = (data: any) => {
  return request("/admin/warehouse/noneedauth/inventory", "GET", data);
};

// 我的审批 通过/驳回
const myreject = (data: any) => {
  return request("/admin/warehouse/warehouseaudit/update", "POST", data);
};
// 屏保管理
const screensaver = (data: any) => {
  return request("/admin/ecard/ecard-screen/select", "GET", data);
};
// 电子班牌 优秀学生 列表
const ecardclassstuselect = (data: any) => {
  return request("/admin/ecard/ecard-class-stu/select", "GET", data);
};
// 电子班牌 优秀学生 删除
const ecardclassstudelete = (data: any) => {
  return request("/admin/ecard/ecard-class-stu/delete", "POST", data);
};
// 电子班牌 优秀学生 添加
const ecardclassstuinsert = (data: any) => {
  return request("/admin/ecard/ecard-class-stu/insert", "POST", data);
};
// 电子班牌  班级下拉列表
const ecardselectclass = (data: any) => {
  return request("/admin/ecard/ecard-news/select-class", "GET", data);
};
// 电子班牌  班级下拉列表学生
const ecardselectstudent = (data: any) => {
  return request("/admin/ecard/ecard-class-stu/select-student", "GET", data);
};
// 上传图片
const uploadFile = (data: any) => {
  return request("/admin/basicdata/upload/avatar", "POST", data);
};
// 上传文件
const uploadFilevideo = (data: any) => {
  return request("/admin/basicdata/upload/file", "POST", data);
};
// 电子班牌  学生作品 列表
const ecardclassopusselect = (data: any) => {
  return request("/admin/ecard/ecard-class-opus/select", "GET", data);
};
// 电子班牌  学生作品 删除
const ecardclassopusdelete = (data: any) => {
  return request("/admin/ecard/ecard-class-opus/delete", "POST", data);
};
// 电子班牌  学生作品 添加
const ecardclassopusinsert = (data: any) => {
  return request("/admin/ecard/ecard-class-opus/insert", "POST", data);
};
// 电子班牌  学生作品 修改
const ecardclassopusupdate = (data: any) => {
  return request("/admin/ecard/ecard-class-opus/update", "POST", data);
};
// 屏保管理-添加
const screensaverinsert = (data: any) => {
  return request("/admin/ecard/ecard-screen/insert", "POST", data);
};
// 屏保管理-修改
const screensaverupdate = (data: any) => {
  return request("/admin/ecard/ecard-screen/update", "POST", data);
};
// 屏保管理-删除
const screensaverudelete = (data: any) => {
  return request("/admin/ecard/ecard-screen/delete", "POST", data);
};
// 霸屏管理
const dominating = (data: any) => {
  return request("/admin/ecard//ecard-screen-disable/select", "GET", data);
};
// 霸屏管理- 添加
const dominatinginster = (data: any) => {
  return request("/admin/ecard//ecard-screen-disable/insert", "POST", data);
};
// 霸屏删除
const disableDelete = (data: any) => {
  return request("/admin/ecard/ecard-screen-disable/delete", "POST", data);
};
// 霸屏管理- 修改
const dominatingupdate = (data: any) => {
  return request("/admin/ecard//ecard-screen-disable/update", "POST", data);
};
// 班级视频/风采
const classelegant = (data: any) => {
  return request("/admin/ecard/ecard-news/select", "GET", data);
};
// 班级视频/风采--添加
const classelegantinsert = (data: any) => {
  return request("/admin/ecard/ecard-news/insert", "POST", data);
};
// 班级视频/风采--修改
const classelegantupdate = (data: any) => {
  return request("/admin/ecard/ecard-news/update", "POST", data);
};
// 班级视频/风采--删除
const classelegantdelete = (data: any) => {
  return request("/admin/ecard/ecard-news/delete", "POST", data);
};
// 电子班牌-系统公告
const classnotice = (data: any) => {
  return request("/admin/ecard//ecard-notify/select", "GET", data);
};
// 电子班牌-系统公告删除
const classnoticedelete = (data: any) => {
  return request("/admin/ecard//ecard-notify/delete", "POST", data);
};
// 电子班牌-系统公告删除
const classnoticeinsert = (data: any) => {
  return request("/admin/ecard//ecard-notify/insert", "POST", data);
};
// 电子班牌-系统公告删除
const classnoticeupdate = (data: any) => {
  return request("/admin/ecard//ecard-notify/update", "POST", data);
};
// 电子班牌 班级下拉框信息
const ecardNewsclassselect = (data: any) => {
  return request("/admin/ecard/ecard-news/select-class", "GET", data);
};
// 请假管理-列表
const leaveselect = (data: any) => {
  return request("/admin/edu/dorm-student-leave/select", "GET", data);
};
// 请假管理-审批记录
const leaveselectlogs = (data: any) => {
  return request("/admin/edu/dorm-student-leave-log/select", "GET", data);
};
// 请假管理-通过/驳回
const leaveupdate = (data: any) => {
  return request("/admin/edu/dorm-student-leave/update", "POST", data);
};
// 我的班级
const myclassdata = (data: any) => {
  return request("/admin/ecard/EcardClassStu/classStudent", "GET", data);
};
// 学生信息及家长信息
const studentclassdata = (data: any) => {
  return request("/admin/ecard/EcardClassStu/studentParents", "GET", data);
};
// 家长绑定学生
const bindstudent = (data: any) => {
  return request("/admin/Login/bindStudent", "POST", data);
};
// 学生列表
const studentlist = (data: any) => {
  return request("/admin/ecard/Family/childrenList", "GET", data);
};
// 请假列表
const leavelist = (data: any) => {
  return request("/admin/ecard/Family/studentLeaveList", "GET", data);
};
// 图片屏保修改
const screensaverUpdate = (data: any) => {
  return request("/admin/ecard/ecard-screen/update", "POST", data);
};
// 请假申请
const leaveapply = (data: any) => {
  return request("/admin/ecard/Family/applyStudentLeave", "POST", data);
};
// 班牌首页数据
const banpaifrist = (data: any) => {
  return request("/admin/ecard/Home/wapHome", "GET", data);
};
// 班牌首页签到统计
const signinstatistics = (data: any) => {
  return request("/admin/ecard/Home/signData", "GET", data);
};
// 根据日期获取课表
const dateclasslist = (data: any) => {
  return request("/admin/ecard/Home/scheduleData", "GET", data);
};
// 留言管理 学生列表
const ecardFamilychildrenList = (data: any) => {
  return request("/admin/ecard/Family/childrenList", "GET", data);
};
// 留言管理 解除绑定
const ecardFamilyunbindStudent = (data: any) => {
  return request("/admin/ecard/Family/unbindStudent", "POST", data);
};
// 留言管理 留言记录
const ecardFamilymsgFamilyList = (data: any) => {
  return request("/admin/ecard/Family/msgFamilyList", "POST", data);
};
// 留言管理 留言记录详情
const ecardFamilymsgFamilyDetail = (data: any) => {
  return request("/admin/ecard/Family/msgFamilyDetail", "POST", data);
};
// 留言管理 发表留言
const ecardFamilysendMessage = (data: any) => {
  return request("/admin/ecard/Family/sendMessage", "POST", data);
};
// 班级课表 获取当日课表
const coursetleselectday = (data: any) => {
  return request("/admin/edu/edu-course-table/select-day", "GET", data);
};
// 获取当前学期 作息时间设置.列表
const coursetleselect = (data: any) => {
  return request(
    "/admin/edu/no-need-auth/select-now-sem-table-set",
    "GET",
    data
  );
};
// 教务课表当前周
const educoursetermInfo = (data: any) => {
  return request("/admin/edu/edu-course-table/termInfo", "GET", data);
};
// 我的课表
const coursetableselect = (data: any) => {
  return request("/admin/edu/edu-course-table/select", "GET", data);
};
// 登陆检测
const checkUser = (data: any) => {
  return request("/admin/wechat/checkUser", "GET", data);
};
// 移动端登陆接口
const accountLogin = (data: any) => {
  return request("/admin/wechat/accountLogin", "GET", data);
};
// 消息信息
const ecardmassage = (data: any) => {
  return request("/admin/ecard/ecard-notify/select", "GET", data);
};
// 餐厅预检测
const checkCanteenLeave = (data: any) => {
  return request("/admin/ecard/Family/checkCanteenLeave", "POST", data);
};
// 家长首页
const studentAttendance = (data: any) => {
  return request("/admin/ecard/Family/studentAttendance", "GET", data);
};
// 餐厅请假
const RenheLeavedateList = (data: any) => {
  return request("/admin/RenheLeave/dateList", "POST", data);
};
// 请假
const RenheLeavemodify = (data: any) => {
  return request("/admin/RenheLeave/modify", "POST", data);
};
// 教职工管理 审批管理 我的请假
const kqleaveapplymyselect = (data: any) => {
  return request("/admin/kq/kqleaveapplymy/select", "GET", data);
};
// 教职工管理 审批管理 我的补卡
const kquncardapplymyselect = (data: any) => {
  return request("/admin/kq/kquncardapplymy/select", "GET", data);
};
// 教职工管理 审批管理 我的请假审核
const kqleaveapplymyauditauditList = (data: any) => {
  return request("/admin/kq/kqleaveapplymyaudit/auditList", "GET", data);
};
// 教职工管理 审批管理 我的补卡审核
const kquncardapplymyauditauditList = (data: any) => {
  return request("/admin/kq/kquncardapplymyaudit/auditList", "GET", data);
};
// 教职工管理 根据成员获取请假规则流程
const noneedauthleaveRule = (data: any) => {
  return request("/admin/kq/noneedauth/leaveRule", "GET", data);
};
// 教职工管理 根据成员ID获取补卡规则
const noneedauthuncardRule = (data: any) => {
  return request("/admin/kq/noneedauth/uncardRule", "GET", data);
};
// 教职工管理 我的请假审核
const kqleaveapplymyauditselect = (data: any) => {
  return request("/admin/kq/kqleaveapplymyaudit/select", "GET", data);
};
// 教职工管理 我的请假审核
const kquncardapplymyauditselect = (data: any) => {
  return request("/admin/kq/kquncardapplymyaudit/select", "GET", data);
};
// 教职工管理 我的请假抄送我
const kqleaveapplymyauditcopy = (data: any) => {
  return request("/admin/kq/kqleaveapplymyaudit/copy", "GET", data);
};
// 教职工管理 我的补卡抄送我
const kquncardapplymyauditcopy = (data: any) => {
  return request("/admin/kq/kquncardapplymyaudit/copy", "GET", data);
};
// 教职工管理 我的请假审核
const kqleaveapplymyauditstatus = (data: any) => {
  return request("/admin/kq/kqleaveapplymyaudit/status", "GET", data);
};
// 教职工考勤 我的补卡审核
const kquncardapplymyauditstatus = (data: any) => {
  return request("/admin/kq/kquncardapplymyaudit/status", "GET", data);
};
// 教职工管理 每日考勤-个人
const kqstatisticsdayperson = (data: any) => {
  return request("/admin/kq/kqstatisticsday/day", "GET", data);
};
// 教职工管理 我的请假撤销
const kqleaveapplymystatus = (data: any) => {
  return request("/admin/kq/kqleaveapplymy/status", "GET", data);
};
// 教职工管理 我的补卡撤销
const kquncardapplymystatus = (data: any) => {
  return request("/admin/kq/kquncardapplymy/status", "GET", data);
};
// 教职工管理 月考勤统计-个人
const kqstatisticsmonthperson = (data: any) => {
  return request("/admin/kq/kqstatisticsday/month", "GET", data);
};
// 教职工管理 考勤设备管理
const kqeqselect = (data: any) => {
  return request("/admin/kq/kqeq/select", "GET", data);
};

// 教职工管理 月度明细-个人
const kqstatisticsdaydetailperson = (data: any) => {
  return request("/admin/kq/kqstatisticsday/detail", "GET", data);
};
// 教职工管理 团队统计
const kqstatisticsdayteam = (data: any) => {
  return request("/admin/kq/kqstatisticsday/team", "GET", data);
};
// 教职工管理 团队月统计明细
const kqstatisticsdayteamdetail = (data: any) => {
  return request("/admin/kq/kqstatisticsday/teamDetail", "GET", data);
};
// 教职工管理 团队成员个人考勤统计
const kqstatisticsdaymoths = (data: any) => {
  return request("/admin/kq/kqstatisticsday/month", "GET", data);
};
// 教职工考勤 考勤设备管理修改
const kqequpdate = (data: any) => {
  return request("/admin/kq/kqeq/update", "POST", data);
};
// 归寝宿舍管理寝室信息列表
const dormdormtypeselect = (data: any) => {
  return request("/admin/dorm/dormtype/select", "GET", data);
};
// 归寝宿舍管理寝室信息添加
const dormdormtypeinsert = (data: any) => {
  return request("/admin/dorm/dormtype/insert", "POST", data);
};
// 归寝宿舍管理寝室信息修改
const dormdormtypeupdate = (data: any) => {
  return request("/admin/dorm/dormtype/update", "POST", data);
};
// 归寝宿舍管理寝室信息删除
const dormdormtypedelete = (data: any) => {
  return request("/admin/dorm/dormtype/delete", "POST", data);
};
// 楼栋信息列表
const buildingselect = (data: any) => {
  return request("/admin/dorm/dormbuilding/select", "GET", data);
};
// 归寝年级
const dormnoneedauthgrades = (data: any) => {
  return request("/admin/dorm/noneedauth/grades", "GET", data);
};
// 归寝学生管理寝室安排批量安排床位one
const dormdormarrangeselect = (data: any) => {
  return request("/admin/dorm/dormarrange/select", "GET", data);
};
// 归寝学生管理寝室安排批量安排床位two  获取寝室学生管理宿舍列表
const dormnoneedauthdorms = (data: any) => {
  return request("/admin/dorm/noneedauth/dorms", "GET", data);
};
// 归寝楼栋
const dormdormBuildings = (data: any) => {
  return request("/admin/dorm/noneedauth/dormBuildings", "GET", data);
};
// 归寝学生管理寝室安排批量安排床位two提交
const dormnoneedauthinsert = (data: any) => {
  return request("/admin/dorm/dormarrange/insert", "POST", data);
};
// 楼栋信息添加
const buildinginsert = (data: any) => {
  return request("/admin/dorm/dormbuilding/insert", "POST", data);
};
// 楼栋信息修改
const buildingupdate = (data: any) => {
  return request("/admin/dorm/dormbuilding/update", "POST", data);
};
// 楼栋信息删除
const buildingdelete = (data: any) => {
  return request("/admin/dorm/dormbuilding/delete", "POST", data);
};
// 归寝宿管
const noneedauthadmins = (data: any) => {
  return request("/admin/dorm/noneedauth/admins", "GET", data);
};
// 归寝学生管理寝室安排单个安排床位
const dormdormarrangestudents = (data: any) => {
  return request("/admin/dorm/dormarrange/students", "GET", data);
};
// 归寝学生管理寝室明细
const dormdormmembeselectr = (data: any) => {
  return request("/admin/dorm/dormmember/select", "GET", data);
};
// 归寝班级
const dormnoneedauthclasses = (data: any) => {
  return request("/admin/dorm/noneedauth/classes", "GET", data);
};
// 归寝学生管理寝室安排单个安排床位提交
const dormdormarrangestudentSave = (data: any) => {
  return request("/admin/dorm/dormarrange/studentSave", "POST", data);
};
// 归寝设备寝室床位管理列表
const dordormbedselect = (data: any) => {
  return request("/admin/dorm/dormbed/select", "GET", data);
};
// 归寝设备寝室床位管理添加
const dordormbedinsert = (data: any) => {
  return request("/admin/dorm/dormbed/insert", "GET", data);
};
// 归寝设备寝室床位管删除
const dordormbeddelete = (data: any) => {
  return request("/admin/dorm/dormbed/delete", "GET", data);
};
// 归寝设备管理放行机管理列表
const dormdevicereleasedelete = (data: any) => {
  return request("/admin/dorm/dormdevicerelease/select", "GET", data);
};
// 归寝设备管理放行机管理列表
const dormdevicereleaseupdate = (data: any) => {
  return request("/admin/dorm/dormdevicerelease/update", "POST", data);
};
// 归寝宿舍管理寝室信息列表
const dormdormselect = (data: any) => {
  return request("/admin/dorm/dorm/select", "GET", data);
};
// 归寝设备管理闸机管理列表
const dormdormgateselect = (data: any) => {
  return request("/admin/dorm/dormgate/select", "GET", data);
};
// 归寝宿舍类型
const dormnoneedauthdormType = (data: any) => {
  return request("/admin/dorm/noneedauth/dormType", "GET", data);
};
// 归寝宿舍管理寝室信息添加
const dormdorminsert = (data: any) => {
  return request("/admin/dorm/dorm/insert", "POST", data);
};
// 归寝宿舍管理寝室信息删除
const dormdormdelete = (data: any) => {
  return request("/admin/dorm/dorm/delete", "POST", data);
};
// 归寝学生管理班级退勤列表
const dormdormquitselect = (data: any) => {
  return request("/admin/dorm/dormquit/select", "GET", data);
};
// 归寝学生管理班级退勤退勤
const dormdormquitdelete = (data: any) => {
  return request("/admin/dorm/dormquit/delete", "POST", data);
};
// 归寝宿舍管理寝室信息修改
const dormdormupdate = (data: any) => {
  return request("/admin/dorm/dorm/update", "POST", data);
};
// 按寝室统计归宿
const dormAttendance = (data: any) => {
  return request("/admin/dorm/DormAttendance/select", "GET", data);
};
// 移动端归寝首页
const statisticsselect = (data: any) => {
  return request("/admin/dorm/statistics/select", "POST", data);
};

// 归寝宿舍管理公告列表
const dormdormnotifyselect = (data: any) => {
  return request("/admin/dorm/dormnotify/select", "GET", data);
};
// 归寝宿舍管理公告删除
const dormdormnotifydelete = (data: any) => {
  return request("/admin/dorm/dormnotify/delete", "POST", data);
};
// 归寝宿舍管理公告添加
const dormdormnotifyinsert = (data: any) => {
  return request("/admin/dorm/dormnotify/insert", "POST", data);
};
// 归寝宿舍管理公告修改
const dormdormnotifyupdate = (data: any) => {
  return request("/admin/dorm/dormnotify/update", "POST", data);
};
// 归寝管理 放行机位置下拉
const noneedauthdevicePosition = (data: any) => {
  return request("/admin/dorm/noneedauth/devicePosition", "GET", data);
};
// 归寝明细
const gqdetailselect = (data: any) => {
  return request("/admin/dorm/DormAttendance/detail", "GET", data);
};
// 设备管理 - 统计
const tuyadevicedetail = (data: any) => {
  return request("/admin/tuya/tuyadevice/statistics", "GET", data);
};
// 设备管理 - 列表
const tuyadeviceselect = (data: any) => {
  return request("/admin/tuya/tuyadevice/select", "GET", data);
};
// 数字教室 信息管理
const tuyahomeroomselect = (data: any) => {
  return request("/admin/tuya/tuyahomeroom/select", "GET", data);
};
// 数字教室  设备管理详情
const tuyahomeroomdetail = (data: any) => {
  return request("/admin/tuya/tuyahomeroom/detail", "GET", data);
};
// 设备总数
const tuyahomeroomcount = (data: any) => {
  return request("/admin/tuya/Statistics/count", "GET", data);
};
// 当日设备耗电量分析
const tuyahomeroomdata = (data: any) => {
  return request("/admin/tuya/Statistics/dayQuantity", "GET", data);
};

// 添加时间策略
const tuyasencerulepoolinsert = (data: any) => {
  return request("/admin/tuya/tuyasencerulepool/insert", "POST", data);
};
// 策略列表
const tuyasencerulepoolselect = (data: any) => {
  return request("/admin/tuya/tuyasencerulepool/select", "GET", data);
};
// 策略列表修改
const tuyasencerulepoolupdate = (data: any) => {
  return request("/admin/tuya/tuyasencerulepool/update", "POST", data);
};
// 联动设备
const conditionDevices = (data: any) => {
  return request("/admin/tuya/noneedauth/conditionDevices", "POST", data);
};
// 获取所有设备
const devices = (data: any) => {
  return request("/admin/tuya/noneedauth/devices", "GET", data);
};
// 策略管理列表
const cerulepoolselect = (data: any) => {
  return request("/admin/tuya/tuyasencerulepool/select", "GET", data);
};
// 策略管理删除
const cerulepooldelete = (data: any) => {
  return request("/admin/tuya/tuyasencerulepool/delete", "POST", data);
};
// 首页对应设备列表
const tuyahomeroomlist = (data: any) => {
  return request("/admin/tuya/tuyahomeroom/list", "GET", data);
};
// 首页所有设备
const tuyahomeroomdevices = (data: any) => {
  return request("/admin/tuya/tuyahomeroom/devices", "GET", data);
};
// 控制空开
const tuyahomeroomswitch = (data: any) => {
  return request("/admin/tuya/tuyahomeroom/switch", "GET", data);
};
// 当日每时耗电量
const hourquantityselect = (data: any) => {
  return request("/admin/tuya/Statistics/hourQuantity", "GET", data);
};
// 首页顶部数据
// 数字教室  涂鸦房间下拉数据
const noneedauthtuyaRooms = (data: any) => {
  return request("/admin/tuya/noneedauth/tuyaRooms", "GET", data);
};
// 数字实训室 实训室信息
const statisticstrainings = (data: any) => {
  return request("/admin/generalTraining/statistics/trainings", "GET", data);
};
// 数字实训室 设备数量统计
const statisticsdevices = (data: any) => {
  return request("/admin/generalTraining/statistics/devices", "GET", data);
};
// 数字实训室 报修数据
const statisticsfaults = (data: any) => {
  return request("/admin/generalTraining/statistics/faults", "GET", data);
};
// 数字实训室 保养数据
const statisticsmaintenances = (data: any) => {
  return request("/admin/generalTraining/statistics/maintenances", "GET", data);
};
// 数字实训室 预警数据
const statisticswarns = (data: any) => {
  return request("/admin/generalTraining/statistics/warns", "GET", data);
};
// 数字实训室 设备管理
const generalTrainingDeviceselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDevice/select",
    "GET",
    data
  );
};
// 数字实训室 设备管理添加
const generalTrainingDeviceinsert = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDevice/insert",
    "POST",
    data
  );
};
// 数字实训室 设备管理修改
const generalTrainingDeviceupdate = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDevice/update",
    "POST",
    data
  );
};
// 数字实训室 实训室下拉
const noneedauthtrainings = (data: any) => {
  return request("/admin/generalTraining/noneedauth/trainings", "GET", data);
};
// 数字实训室 设备类型下拉
const noneedauthdeviceCates = (data: any) => {
  return request("/admin/generalTraining/noneedauth/deviceCates", "GET", data);
};
// 实训室信息
const trainingsmanage = (data: any) => {
  return request("/admin/generalTraining/statistics/trainings", "GET", data);
};
// 保养数据
const maintenancesdata = (data: any) => {
  return request("/admin/generalTraining/statistics/maintenances", "GET", data);
};
// 设备数量统计
const devicesdataa = (data: any) => {
  return request("/admin/generalTraining/statistics/devices", "GET", data);
};
// 报修数据
const faultsdataa = (data: any) => {
  return request("/admin/generalTraining/statistics/faults", "GET", data);
};
// 设别类型
const deviceCates = (data: any) => {
  return request("/admin/generalTraining/noneedauth/deviceCates", "GET", data);
};
// 实训室类型
const trainingstype = (data: any) => {
  return request("/admin/generalTraining/noneedauth/trainings", "GET", data);
};
// 保养计划
const generalTrainingPlan = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingPlan/select",
    "GET",
    data
  );
};
// 维修记录
const generalTrainingDeviceFaultLogs = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDeviceFaultLogs/select",
    "GET",
    data
  );
};
// 报修列表
const generalTrainingDeviceFault = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDeviceFault/select",
    "GET",
    data
  );
};
// 报修撤销
const generalTrainingDeviceFaultcancel = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDeviceFault/cancel",
    "GET",
    data
  );
};
// 报修删除
const generalTrainingDeviceFaultdelete = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDeviceFault/delete",
    "POST",
    data
  );
};

// 保养列表
const generalTrainingselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingTask/select",
    "GET",
    data
  );
};
// 故障上报
const generalTrainingDeviceFaultHandleselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDeviceFaultHandle/select",
    "GET",
    data
  );
};

// 数字实训室 故障报修处理维修单
const generalTrainingDeviceFaultHandlehandle = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDeviceFaultHandle/handle",
    "POST",
    data
  );
};
// 故障上报
const generalTrainingDeviceFaultHandleupdate = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDeviceFaultHandle/update",
    "POST",
    data
  );
};
//
const statisticsmyFaults = (data: any) => {
  return request("/admin/generalTraining/statistics/myFaults", "POST", data);
};
//
const statisticsmyRepair = (data: any) => {
  return request("/admin/generalTraining/statistics/myRepair", "POST", data);
};
// 修改保养任务
const generalTrainingTaskupdate = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingTask/update",
    "POST",
    data
  );
};
// 保养列表
const generalTrainingTaskLogsselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingTaskLogs/select",
    "GET",
    data
  );
};
// 上传附件
const upload_attachment = (data: any) => {
  return request("/admin/basicdata/upload/attachment", "POST", data);
};
// 获取公众号授权信息
const wechat_qrcode = (data: any) => {
  return request("/admin/wechat/qrcode", "POST", data);
};
// 获取扫码id获取设备详情
const generalTrainingDevice_info = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDevice/info",
    "GET",
    data
  );
};
// 获取故障报修
const generalTrainingDeviceFault_insert = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingDeviceFault/insert",
    "POST",
    data
  );
};
// 班牌预警
const ecardselect = (data: any) => {
  return request("/admin/ecard/ecard/select", "GET", data);
};
// 班牌预警 楼栋
const selectbuilding = (data: any) => {
  return request("/admin/ecard/no-need-auth/select-building", "GET", data);
};
// 班牌预警 房间
const selectroom = (data: any) => {
  return request("/admin/ecard/no-need-auth/select-building-room", "GET", data);
};
// 实训室预警 列表
const generalTrainingdeviceWarnselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalTrainingdeviceWarn/select",
    "GET",
    data
  );
};
// 实训室预警 设备
const noneedauthdevices = (data: any) => {
  return request("/admin/generalTraining/noneedauth/devices", "GET", data);
};
// 实训室 采购清单
const GeneralDeviceFaultPurchaseGood = (data: any) => {
  return request(
    "/admin/generalTraining/GeneralDeviceFaultPurchaseGood/select",
    "GET",
    data
  );
};
// 实训室 采购
const GeneralDeviceFaultPurchaseGoodupdate = (data: any) => {
  return request(
    "/admin/generalTraining/GeneralDeviceFaultPurchaseGood/update",
    "POST",
    data
  );
};
//数字实训室  采购申请
const generalDeviceFaultPurchaseselect = (data: any) => {
  return request(
    "/admin/generalTraining/generalDeviceFaultPurchase/select",
    "GET",
    data
  );
};
//数字实训室  采购审批 列表
const GeneralDeviceFaultPurchaseAuditselect = (data: any) => {
  return request(
    "/admin/generalTraining/GeneralDeviceFaultPurchaseAudit/select",
    "GET",
    data
  );
};
//数字实训室  采购审批 审批
const GeneralDeviceFaultPurchaseAuditupdate = (data: any) => {
  return request(
    "/admin/generalTraining/GeneralDeviceFaultPurchaseAudit/update",
    "POST",
    data
  );
};
//数字实训室  采购申请 配件取消
const generalDeviceFaultPurchasegood = (data: any) => {
  return request(
    "/admin/generalTraining/generalDeviceFaultPurchase/good",
    "GET",
    data
  );
};
//数字实训室  采购申请 取消申请
const generalDeviceFaultPurchaseupdate = (data: any) => {
  return request(
    "/admin/generalTraining/generalDeviceFaultPurchase/update",
    "POST",
    data
  );
};
// 数字实训室 所有设备
const generalTrainingnoneedauthdevices = (data: any) => {
  return request("/admin/generalTraining/noneedauth/devices", "GET", data);
};
// 教师考勤 请假申请
const kqleaveapplymyinsert = (data: any) => {
  return request("/admin/kq/kqleaveapplymy/insert", "POST", data);
};
// 教师考勤 补卡申请
const kquncardapplymyinsert = (data: any) => {
  return request("/admin/kq/kquncardapplymy/insert", "POST", data);
};
// 库存警报
const warehousegoodswarn = (data: any) => {
  return request("/admin/warehouse/warehousegoodswarn/select", "GET", data);
};
// 获取所有立达信房间
const leedarsonRoomsselect = (data: any) => {
  return request("/admin/leedarson/noneedauth/leedarsonRooms", "GET", data);
};
// 网关管理详情
const LdsDevicedetail = (data: any) => {
  return request("/admin/leedarson/LdsDevice/detail", "GET", data);
};
// 网关管理详情
const LdsDevicecontrol = (data: any) => {
  return request("/admin/leedarson/LdsDevice/control", "POST", data);
};
// 策略管理-列表
const LdsStrategyPoolselect = (data: any) => {
  return request("/admin/leedarson/LdsStrategyPool/select", "GET", data);
};
// 策略管理-删除
const LdsStrategyPooldelete = (data: any) => {
  return request("/admin/leedarson/LdsStrategyPool/delete", "POST", data);
};
// 获取所有条件设备
const noneedauthgetCondition = (data: any) => {
  return request("/admin/leedarson/noneedauth/getCondition", "GET", data);
};
// 获取所有执行设备
const noneedauthgetAction = (data: any) => {
  return request("/admin/leedarson/noneedauth/getAction", "GET", data);
};
// 策略管理新增
const LdsStrategyPoolinsert = (data: any) => {
  return request("/admin/leedarson/LdsStrategyPool/insert", "POST", data);
};
// 策略管理修改
const LdsStrategyPoolupdate = (data: any) => {
  return request("/admin/leedarson/LdsStrategyPool/update", "POST", data);
};
// 解绑
const wechatunbind = (data: any) => {
  return request("/admin/wechat/unbind", "POST", data);
};
export {
  wechatunbind,
  LdsStrategyPoolinsert,
  LdsStrategyPoolupdate,
  noneedauthgetCondition,
  noneedauthgetAction,
  LdsDevicecontrol,
  LdsDevicedetail,
  leedarsonRoomsselect,
  LdsStrategyPoolselect,
  LdsStrategyPooldelete,
  GeneralDeviceFaultPurchaseGood,
  GeneralDeviceFaultPurchaseGoodupdate,
  warehousegoodswarn,
  kquncardapplymyinsert,
  kqleaveapplymyinsert,
  generalTrainingnoneedauthdevices,
  generalDeviceFaultPurchaseupdate,
  generalDeviceFaultPurchasegood,
  GeneralDeviceFaultPurchaseAuditupdate,
  GeneralDeviceFaultPurchaseAuditselect,
  generalDeviceFaultPurchaseselect,
  newspasswd,
  noneedauthdevices,
  generalTrainingdeviceWarnselect,
  selectroom,
  selectbuilding,
  ecardselect,
  main,
  generalTrainingDeviceFault_insert,
  generalTrainingDevice_info,
  wechat_qrcode,
  generalTrainingDeviceupdate,
  upload_attachment,
  generalTrainingDeviceinsert,
  generalTrainingTaskLogsselect,
  generalTrainingTaskupdate,
  statisticsmyRepair,
  statisticsmyFaults,
  generalTrainingDeviceFaultdelete,
  generalTrainingDeviceFaultcancel,
  generalTrainingDeviceFaultHandleupdate,
  generalTrainingDeviceFaultHandleselect,
  generalTrainingDeviceFaultHandlehandle,
  generalTrainingselect,
  generalTrainingDeviceFault,
  generalTrainingDeviceFaultLogs,
  generalTrainingPlan,
  trainingstype,
  deviceCates,
  noneedauthdeviceCates,
  noneedauthtrainings,
  generalTrainingDeviceselect,
  faultsdataa,
  devicesdataa,
  maintenancesdata,
  trainingsmanage,
  statisticswarns,
  statisticsmaintenances,
  statisticsfaults,
  statisticsdevices,
  statisticstrainings,
  noneedauthprojecting,
  noneedauthtuyaRooms,
  tuyahomeroomswitch,
  tuyahomeroomdevices,
  tuyahomeroomlist,
  hourquantityselect,
  cerulepooldelete,
  cerulepoolselect,
  devices,
  conditionDevices,
  tuyasencerulepoolinsert,
  tuyasencerulepoolselect,
  tuyasencerulepoolupdate,
  tuyahomeroomdata,
  tuyahomeroomcount,
  tuyahomeroomdetail,
  tuyahomeroomselect,
  tuyadeviceselect,
  tuyadevicedetail,
  projects,
  leaveselectlogs,
  gqdetailselect,
  noneedauthdevicePosition,
  statisticsselect,
  dormdormnotifyinsert,
  dormdormnotifyupdate,
  dormdormnotifydelete,
  dormdormnotifyselect,
  dormdormquitdelete,
  dormdormquitselect,
  dormAttendance,
  dormdormupdate,
  dormdormgateselect,
  dormdevicereleaseupdate,
  dormdevicereleasedelete,
  dormdormdelete,
  dormdorminsert,
  dormnoneedauthdormType,
  dordormbeddelete,
  dordormbedinsert,
  dordormbedselect,
  dormdormselect,
  dormdormarrangestudentSave,
  dormdormarrangestudents,
  dormnoneedauthclasses,
  dormnoneedauthinsert,
  dormdormBuildings,
  dormnoneedauthdorms,
  dormdormarrangeselect,
  dormnoneedauthgrades,
  dormdormmembeselectr,
  buildingdelete,
  buildingupdate,
  noneedauthadmins,
  buildinginsert,
  dormdormtypedelete,
  buildingselect,
  dormdormtypeselect,
  dormdormtypeinsert,
  dormdormtypeupdate,
  kqequpdate,
  kqeqselect,
  kqstatisticsdaymoths,
  kqstatisticsdayteamdetail,
  kqstatisticsdayteam,
  kqstatisticsdaydetailperson,
  kqleaveapplymystatus,
  kquncardapplymystatus,
  kquncardapplymyauditstatus,
  kqleaveapplymyauditstatus,
  kqstatisticsmonthperson,
  kqstatisticsdayperson,
  kqleaveapplymyauditcopy,
  kquncardapplymyauditcopy,
  kqleaveapplymyauditselect,
  kquncardapplymyauditselect,
  noneedauthleaveRule,
  noneedauthuncardRule,
  kquncardapplymyauditauditList,
  kqleaveapplymyauditauditList,
  kquncardapplymyselect,
  kqleaveapplymyselect,
  RenheLeavemodify,
  RenheLeavedateList,
  wechatLogout,
  studentAttendance,
  checkCanteenLeave,
  fetchFamilyOpenid,
  ecardmassage,
  accountLogin,
  checkUser,
  unlogin,
  moduleMenu,
  coursetableselect,
  educoursetermInfo,
  coursetleselect,
  coursetleselectday,
  ecardFamilysendMessage,
  ecardFamilymsgFamilyDetail,
  ecardFamilymsgFamilyList,
  ecardFamilyunbindStudent,
  ecardFamilychildrenList,
  dateclasslist,
  signinstatistics,
  banpaifrist,
  leaveapply,
  leavelist,
  studentlist,
  bindstudent,
  studentclassdata,
  myclassdata,
  leaveupdate,
  leaveselect,
  classnoticeupdate,
  classnoticeinsert,
  classnoticedelete,
  classnotice,
  classelegantdelete,
  classelegantupdate,
  classelegantinsert,
  classelegant,
  dominatingupdate,
  disableDelete,
  dominatinginster,
  dominating,
  screensaverudelete,
  screensaverupdate,
  screensaverinsert,
  screensaver,
  myreject,
  allinventory,
  datestatistics,
  warehouseUpdate,
  myapplyInsert,
  allintools,
  myapplyDetail,
  circulateLog,
  alladmin,
  myapplySelect,
  myApprove,
  login,
  notice,
  mymessage,
  schooldata,
  statisticsIndex,
  warehouseSelect,
  allwarehouses,
  warningSelect,
  toolsguanli,
  myApprovelog,
  ecardclassstuselect,
  ecardclassstudelete,
  ecardclassstuinsert,
  ecardselectclass,
  ecardselectstudent,
  uploadFile,
  ecardclassopusselect,
  ecardclassopusinsert,
  ecardclassopusupdate,
  ecardclassopusdelete,
  ecardNewsclassselect,
  uploadFilevideo,
  screensaverUpdate,
  permission,
  adminInfo,
  noneedauthgoodslib,
};
